<template>
  <div class="body container">
    <Breadcrumb />
    <CreateButton v-if="isRoleAdmin()" />
    <h1>{{ this.pageTitle }}</h1>
    <el-table :data="payments" width="100%">
      <el-table-column
        label="Teacher Name"
        prop="teacher_user.name"
        width="130"
      />
      <el-table-column label="Method" prop="title" width="120" />
      <el-table-column label="Details">
        <template slot-scope="scope">
          <span :key="index" v-for="(payment, index) in scope.row.payment">
            {{ payment[Object.keys(payment)[0]]
            }}<span v-if="scope.row.payment.length - 1 !== index">,</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Note" prop="note" width="120" />
      <el-table-column label="Date since" width="120">
        <template slot-scope="scope">
          {{ instant.formatDate(scope.row.created_at) }}
        </template>
      </el-table-column>
      <el-table-column label="Is Default" width="120">
        <template slot-scope="scope">
          {{ scope.row.is_default ? "O" : "X" }}
        </template>
      </el-table-column>
      <el-table-column width="100">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            content="Edit"
            placement="top"
            popper-class="tooltipColor"
          >
            <router-link
              :to="{
                name: 'editPayment',
                params: { teacherPaymentId: scope.row.id }
              }"
            >
              <i class="el-icon-edit-outline action-icon" />
            </router-link>
          </el-tooltip>
          <el-tooltip
            class="item"
            content="Edit"
            placement="top"
            popper-class="tooltipColor"
            v-if="$store.getters['user/isRole']('super-admin')"
          >
            <DeleteIconButton :onDelete="deleteTeacherPayments(scope.row.id)" />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { instant } from "@ivy-way/material";
import "@/assets/scss/style.scss";
import Breadcrumb from "@/components/Breadcrumb";
import DeleteIconButton from "@/components/button/DeleteIconButton";
import CreateButton from "./CreateButton";
import paymentApi from "@/apis/payment";
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    CreateButton,
    DeleteIconButton
  },
  mixins: [moneyMixin, roleMixin],
  data() {
    return {
      payments: []
    };
  },
  computed: {
    pageTitle() {
      return this.$t("pageTitle.payment");
    },
    instant() {
      return instant;
    }
  },
  async created() {
    await this.fetchTeacherPayments();
  },
  methods: {
    filterEmpty(paymentInformation) {
      return paymentInformation.filter(
        payment => payment[Object.keys(payment)[0]]
      );
    },
    async fetchTeacherPayments() {
      let teacherUserPayments = [];
      if (this.isRoleAdmin()) {
        const teacherPaymentRes = await paymentApi.fetchTeacherPayments();
        teacherUserPayments = teacherPaymentRes.teacher_user_payments;
      } else if(this.isRoleTeacher()) {
        const teacherPaymentRes = await paymentApi.fetchTeacherPayments(this.$store.getters["user/getProfile"].id);
        teacherUserPayments = teacherPaymentRes.teacher_user_payments;
      }

      this.payments = teacherUserPayments.map(teacherPayment => ({
        ...teacherPayment,
        payment: this.filterEmpty(teacherPayment.payment)
      }));
    },
    deleteTeacherPayments(teacherPaymentId) {
      return async () => {
        await paymentApi.deleteTeacherPayments(teacherPaymentId);
        this.fetchTeacherPayments();
      };
    }
  }
};
</script>

<style scoped>
::v-deep .el-pagination {
  margin: 20px 0px;
}

::v-deep .el-step__description {
  padding-right: 0px;
}
</style>
