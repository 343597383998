<template>
  <div class="createButtonWrapper">
    <router-link :to="{ name: 'createPayment' }">
      <el-button
        circle
        type="danger"
        icon="el-icon-plus"
        class="createButton"
      />
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.createButtonWrapper {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 9;

  .createButton {
    font-size: 32px;
    width: 60px;
    height: 60px;
  }
}
</style>
